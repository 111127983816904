<template>
  <div class="landingPage">
    <nav>
      <div class="inner flexB">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/images/logo.svg" alt="로고"
          /></router-link>
        </div>
        <router-link class="loginBtn" to="/account/login">로그인</router-link>
      </div>
    </nav>
    <section class="legalContent">
      <div class="inner">
        <h1>Privacy Policy</h1>
        <ol>
          <li>
            <h3>Introduction</h3>
            <p>
              Thank you for choosing TalkTalkTalk. Your privacy is critically
              important to us. This Privacy Policy explains what information
              TalkTalkTalk ("we", "us", or "our") collects from users ("you" or
              "your") of our applications ("Apps") on Google Play and how we use
              it. By using our Apps, you agree to the collection and use of
              information in accordance with this policy.
            </p>
          </li>
          <li>
            <h3>Information Collection and Use</h3>
            <p class="mb0">
              When you use our apps, we collect the following personal
              information from you:
            </p>
            <ul>
              <li>Your email address</li>
              <li>Your native language</li>
            </ul>
            <p>How We Use Your Personal Information</p>
            <p class="mb0">
              We use your personal information for the following purposes:
            </p>
            <ul>
              <li>To provide you with our apps and services</li>
              <li>To improve our apps and services</li>
              <li>To provide and maintain the Apps</li>
              <li>To notify you about changes to our Apps</li>
              <li>To provide customer care and support</li>
              <li>
                To gather analysis or valuable information so that we can
                improve the Apps
              </li>
              <li>
                To monitor the usage of the Apps To detect, prevent and address
                technical issues
              </li>
              <li>To send you marketing communications</li>
              <li>
                To respond to your feedback and comments To comply with
                applicable laws and regulations
              </li>
            </ul>
            <p>How We Share Your Personal Information</p>
            <p class="mb0">
              We may share your personal information with the following third
              parties:
            </p>
            <ul>
              <li>
                Our service providers, who help us provide our apps and services
              </li>
              <li>
                Our marketing partners, who help us market our apps and services
              </li>
              <li>
                Our legal and regulatory advisors, who help us comply with
                applicable laws and regulations
              </li>
            </ul>
            <p>Your Privacy Rights</p>
            <p class="mb0">You have the following privacy rights:</p>
            <ul>
              <li>The right to access your personal information</li>
              <li>The right to correct your personal information</li>
              <li>The right to delete your personal information</li>
              <li>
                The right to object to the processing of your personal
                information
              </li>
              <li>
                The right to restrict the processing of your personal
                information
              </li>
              <li>The right to port your personal information</li>
              <li>The right to complain to a data protection authority</li>
            </ul>
          </li>
          <li>
            <h3>Data Sharing</h3>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our Apps,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when its release is appropriate to comply with
              the law, enforce our site policies, or protect our or others'
              rights, property, or safety.
            </p>
          </li>
          <li>
            <h3>Security</h3>
            <p>
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage, is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
          </li>
          <li>
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </p>
          </li>
          <li>
            <h3>Contact Us</h3>
            <p class="mb0">
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>
            <ul>
              <li>
                By email:
                <a href="mailto:batoncompany.caster@gmail.com"
                  >batoncompany.caster@gmail.com</a
                >
              </li>
            </ul>
            <p>Effective date: 9 June 2024</p>
          </li>
        </ol>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
